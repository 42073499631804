import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "@/components/layouts/app/AppLayout";
import store from "@/store/index";
import { TokenService } from "@/services/storage.service";

Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
};

const routes = [
  {
    path: "",
    redirect: { name: "Registration" },
  },
  {
    path: "*",
    redirect: { name: "Registration" },
  },
  {
    path: "/example",
    name: "Example",
    component: () => import("../views/Example.vue"),
  },
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        meta: {
          public: true,
        },
        path: "Registration",
        component: EmptyParentComponent,
        children: [
          {
            name: "Registration",
            path: "",
            component: () => import("../views/Registration/HomeThummayan.vue"),
          },
          {
            name: "Confirm",
            path: "Confirm",
            component: () =>
              import("../views/Registration/SecondThammayan.vue"),
          },
          {
            // meta: {
            //   rootMenu: "Registration",
            // },
            // path: "FormRegis/:stap",
            name: "FormRegis",
            path: "FormRegis",
            component: () => import("../views/Registration/FormRegis.vue"),
          },
          {
            name: "Preview",
            path: "Preview",
            component: () => import("../views/Registration/FormPreview.vue"),
          },
        ],
      },
      {
        path: "Manage",
        component: EmptyParentComponent,
        children: [
          {
            name: "Manage",
            path: "Menu",
            component: () => import("../views/ManageProject/HomeAdmin.vue"),
          },
          {
            name: "ListOrdination",
            path: "ListOrdination",
            component: () =>
              import("../views/ManageProject/ListOrdination.vue"),
          },
          {
            name: "CreateOrdination",
            path: "CreateOrdination/:mode",
            component: () =>
              import("../views/ManageProject/CreateOrdination.vue"),
          },
          {
            path: "OrdinationProject",
            component: EmptyParentComponent,
            children: [
              {
                name: "OrdinationProject",
                path: "",
                component: () =>
                  import("../views/ManageCandidate/ListDetailOrdination.vue"),
              },
              {
                path: "ListCandidate",
                component: EmptyParentComponent,
                children: [
                  {
                    name: "ListCandidate",
                    path: "",
                    component: () =>
                      import("../views/ManageCandidate/ListNameOrdination.vue"),
                  },
                  {
                    name: "DetailCandidate",
                    path: "DetailCandidate",
                    component: () =>
                      import("../views/ManageCandidate/ViewOrdination.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    component: AppLayout,
    meta: {
      login: true,
    },
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("../views/Auth/Login.vue"),
      },
    ],
  },

  // {
  //   path: "/ListDetailOrdination",
  //   component: AppLayout,
  //   meta: {
  //     public: true,
  //   },
  //   children: [
  //     {
  //       name: "ListDetailOrdination",
  //       path: "",
  //       component: () => import("../views/Manage/ListDetailOrdination.vue"),
  //     },
  //   ],
  // },
  // {
  //   path: "/ListNameOrdination",
  //   component: AppLayout,
  //   meta: {
  //     public: true,
  //   },
  //   children: [
  //     {
  //       name: "ListNameOrdination",
  //       path: "",
  //       component: () => import("../views/Manage/ListNameOrdination.vue"),
  //     },
  //   ],
  // },
  // {
  //   path: "/ViewOrdination",
  //   component: AppLayout,
  //   meta: {
  //     public: true,
  //   },
  //   children: [
  //     {
  //       name: "ViewOrdination",
  //       path: "",
  //       component: () => import("../views/Manage/ViewOrdination.vue"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setPageLoading", true);

  let changeNext;

  const haveToken = !!TokenService.getToken();
  const toMatchedLogin = to.matched.some((record) => record.meta.login);
  const toMatchedPublic = to.matched.some((record) => record.meta.public);

  if (toMatchedPublic && haveToken) {
    return next({ name: "Manage" });
  } else if ((toMatchedLogin && !haveToken) || toMatchedPublic) {
  } else if (!haveToken) {
    return next({ name: "Login" });
  } else if (toMatchedLogin && haveToken) {
    return next({ name: "Manage" });
  } else {
  }

  if (changeNext) {
    if (from.name === changeNext) {
      // hide loading page if from and next is same route
      store.commit("setPageLoading", false);
    }
    changeNext = { name: changeNext };
  }

  return next(changeNext);
});

router.afterEach(async (to, from, next) => {
  store.commit("setPageLoading", false);
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

export default router;
