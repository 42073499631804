import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vuelidate from "vuelidate";

import "@/utils/fonts/THSarabunNew-normal.js";
import "@/utils/fonts/THSarabunNew-bold.js";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import vuetify from "@/plugins/vuetify";
import ComponentPlugin from "@/components/cores/_ComponentPlugin";
import { VueReCaptcha } from "vue-recaptcha-v3";
import mainUtils from "@/utils/main-utils";
import convertUtils from "@/utils/convert-utils";

/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueSweetalert2);
Vue.use(Vuelidate);

Vue.use(ComponentPlugin);

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

Vue.prototype.$utils = {
  main: { ...mainUtils },
  convert: { ...convertUtils },
};

library.add(fas);
library.add(far);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

Vue.component("multiselect", {
  mixins: [Multiselect],
  props: {
    label: {
      default: "name",
    },
    selectLabel: {
      default: "",
    },
    deselectLabel: {
      default: "X",
    },
    selectedLabel: {
      default: "",
    },
    showLabels: {
      default: true,
    },
    closeOnSelect: {
      default: true,
    },
    searchable: {
      default: true,
    },
    placeholder: {
      default: "",
    },
    // showNoOptions: {
    //   default: false,
    // },
    // showNoResults: {
    //   default: false,
    // },
  },
  // slots: {
  // },
});

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    alertSuccess(title, text, timer = 2500) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="h4">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="h4">${title}</div>`);
      } else {
        _title.push(`<div class="h4">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="h5">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="h5">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: false,
            // confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertFail(title, text, timer = 2500) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="h4">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="h4">${title}</div>`);
      } else {
        _title.push(`<div class="h4">ทำรายการไม่สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="h5">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="h5">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: false,
            // confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "error",
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertSuccessConfirm(title, text) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="h4">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="h4">${title}</div>`);
      } else {
        _title.push(`<div class="h4">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="h5">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="h5">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn button-success",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertConfirm(title, text, confirmButtonText = "ยืนยัน", showCancel = true) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="h4">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="h4">${title}</div>`);
      } else {
        _title.push(`<div class="h4">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="h5">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="h5">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: showCancel,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: "ยกเลิก",
            customClass: {
              confirmButton: "btn button-success mx-2",
              cancelButton: "btn button-danger mx-2",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "question",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
