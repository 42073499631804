<template>
  <multiselect
    v-model="value2"
    :options="options"
    @select="changeOrg"
    :disabled="disabled"
    :placeholder="placeholder"
    :allowEmpty="allowEmpty"
    :showLabels="allowEmpty"
  >
    <template slot="option" slot-scope="props">
      <div class="org-options">
        <small class="org-detail">{{ props.option.detail }}</small>
        <div class="org-text">{{ props.option.text }}</div>
      </div>
    </template>
    <span slot="noResult">ไม่มีข้อมูล</span>
    <span slot="noOptions">ไม่มีข้อมูล</span>
  </multiselect>
</template>

<script>
export default {
  name: "multiselect-org",
  props: {
    value: {
      default: null,
    },
    disabled: {
      default: null,
    },
    placeholder: {
      default: null,
    },
    allowEmpty: {
      default: true,
    },
    options: {
      type: [Array, null, undefined],
      default: [],
    },
  },
  data() {
    return {
      value2: null,
      //   options2: null,
    };
  },
  methods: {
    changeOrg(event) {
      this.$emit("input", event);
      this.$emit("select", event);
    },
  },
  watch: {
    // options: {
    //   handler: function (val) {
    //     this.options2 = val;
    //   },
    //   deep: true,
    // },
    value: {
      handler: function (val) {
        this.value2 = val;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.org-options {
  .org-text {
    margin-top: 5px;
  }
}
</style>
