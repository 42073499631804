const toFixed = (number, limit) => {
  if (number !== null && number !== "" && number !== undefined) {
    return Number(number).toFixed(limit);
  }
  return number;
};

const toCommas = (number, limit) => {
  if (
    number !== null &&
    number !== "" &&
    number !== undefined &&
    typeof number === "number"
  ) {
    // .toLocaleString()
    return Number(number)
      .toFixed(limit)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number;
};

const JsonToString = (json = null) => {
  try {
    if (json) {
      return JSON.stringify(json);
    } else {
      return json;
    }
  } catch (e) {
    return undefined;
  }
};

const StringToJson = (data = "") => {
  try {
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return undefined;
  }
};

const checkFileSize = (file, size) => {
  let check = null;

  if (size === null) {
    size = 2097152;
  }

  //default size => 2097152 = 2MB
  if (file.size > size) {
    check = false;
  } else {
    check = true;
  }

  return check;
};

const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

const isTypeFileImage = (file = []) => {
  const _typeFile = ["image/jpeg", "image/jpg", "image/png"];

  if (file != null) {
    return _typeFile.includes(file.type);
  } else {
    return false;
  }
};

const getExtension = (filename) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

const objRemoveNull = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  });

  return object;
};

const objToFormData = (object, form_data, mainstr = null) => {
  object = objRemoveNull(object);

  for (var key in object) {
    form_data.append(`${mainstr ? mainstr + "." : ""}${key}`, object[key]);
  }

  return form_data;
};

const arrToFormData = (array = [], form_data, mainstr = null) => {
  if (array.length > 0) {
    array.forEach((object, i) => {
      const keyArr = `${mainstr}[${i}]`;
      object = objRemoveNull(object);

      for (var key in object) {
        const _f1 = `${keyArr}.${key}`;
        form_data.append(_f1, object[key]);
      }
    });
  }

  return form_data;
};

const objToFormDataFile = (array, form_data, mainstr = null) => {
  // object = objRemoveNull(array);
  array.forEach((element, i) => {
    element = objRemoveNull(element);
    // const keyDoc = `${mainstr}[${i}]`;
    const keyDoc = `${mainstr}`;
    form_data.append(`${keyDoc}`, element);
  });

  return form_data;
};

const logFormData = (formData) => {
  console.log("startttt");
  for (const pair of formData.entries()) {
    console.log(pair[0], ",", pair[1]);
  }
  console.log("doneeee");
};

export default {
  toFixed,
  toCommas,
  JsonToString,
  StringToJson,
  checkFileSize,
  formatBytes,
  isTypeFileImage,
  getExtension,
  objToFormData,
  objToFormDataFile,
  logFormData,
  objRemoveNull,
  arrToFormData,
};

export {
  toFixed,
  toCommas,
  JsonToString,
  StringToJson,
  checkFileSize,
  formatBytes,
  isTypeFileImage,
  getExtension,
  objToFormData,
  objToFormDataFile,
  logFormData,
  objRemoveNull,
  arrToFormData,
};
