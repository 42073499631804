<template>
  <div id="app">
    <div v-if="isPageLoading" class="loading-page">
      <b-spinner
        v-show="isPageLoading"
        class="pre-loader"
        variant="navy"
        label="Loading..."
        style="width: 5rem; height: 5rem"
      ></b-spinner>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { sizes, subscribe, unsubscribe } from "@/utils/media-query";

// function getScreenSizeInfo() {
//   // const screenSizes = sizes();
//   return {
//     // isMedium: screenSizes["screen-medium"],
//     // isLarge: screenSizes["screen-large"],
//     // cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
//   };
// }

export default {
  name: "app",
  data() {
    return {
      // screen: getScreenSizeInfo(),
    };
  },
  created() {
    const _env = process.env.NODE_ENV;
    const disabledLog = !process.env.VUE_APP_CONSOLE_LOG;
    console.log("_env", _env, disabledLog);
    console.log("version", process.env.VUE_APP_BASE_WEB_VERSION);

    if ((_env === "production" && disabledLog) || disabledLog) {
      // Disable console log // on production
      console.log = function () {};
    }
  },
  computed: {
    ...mapGetters(["isPageLoading"]),
    // cssClasses() {
    //   return `app-${this.screen.cssClasses}`;
    // },
  },
  // methods: {
  //   screenSizeChanged() {
  //     this.screen = getScreenSizeInfo();
  //   },
  // },
  // mounted() {
  //   subscribe(this.screenSizeChanged);
  // },
  // beforeDestroy() {
  //   unsubscribe(this.screenSizeChanged);
  // },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.loading-page {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(white, 0.5);
  z-index: 10000;
}
</style>
