import store from "@/store/index";
// import { StringToJson } from "./convert-utils";

const getUserInfo = () => {
  const _detail = store.state.account?.userInfo || {};
  return { ..._detail };
};

export default {
  getUserInfo,
};
