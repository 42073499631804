<template>
  <div id="AppHeader" style="position: fixed; width: 100%; z-index: 99">
    <div class="app-header shadow-sm">
      <b-navbar toggleable="md" type="dark" class="header-color">
        <!-- <font-awesome-icon icon="fa-solid fa-synagogue" /> -->
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="goTo('Registration')"
        >
          <img src="@/assets/logot.png" class="logo-header mr-2" />
          <span class="font-white weight-600 font-size-h4">ระบบสมัครบรรพชา</span
          ><!-- อุปสมบท -->
        </div>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <template v-if="isLoginAdmin">
              <div class="d-flex align-items-center mx-3 text-white">
                <font-awesome-icon icon="fa-solid fa-user" />
                <span class="ml-2 weight-600">{{ usernameLogin }}</span>
              </div>
              <div
                class="d-flex align-items-baseline cursor-pointer btn button-officer"
                @click="getLogout"
              >
                <span class="ml-2">ออกจากระบบ</span>
              </div>
            </template>
            <template v-else>
              <div
                class="d-flex align-items-baseline cursor-pointer btn button-officer"
                @click="goTo('Login')"
              >
                <font-awesome-icon icon="fa-solid fa-user" />
                <span class="ml-2">สำหรับเจ้าหน้าที่</span>
              </div>
            </template>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header",
  data() {
    return {};
  },
  methods: {
    getLogout() {
      this.alertConfirm("คุณต้องการออกจากระบบใช่หรือไม่").then((res) => {
        if (res) {
          this.$store
            .dispatch("account/logout")
            .then(
              (res) => {},
              (error) => {
                console.log("getLogout():error", error);
              }
            )
            .finally(() => {
              this.$router.push({ name: "Registration" });
            });
        }
      });
    },
    goTo(name) {
      this.$router.push({
        name: name,
      });
    },
  },
  computed: {
    usernameLogin: function () {
      const detail = this.$utils.main.getUserInfo() || {};
      return detail?.userName || "-";
    },
    isLoginAdmin: function () {
      const detail = this.$utils.main.getUserInfo() || {};
      return detail?.userName != undefined;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem !important;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #fce38a;
    }
  }
}

.header-color {
  background-color: #d77635;
}
</style>
