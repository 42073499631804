<template>
  <div id="AppLayout">
    <app-header></app-header>

    <main class="content-wrapper">
      <router-view></router-view>
    </main>

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import AppHeader from "./Header.vue";
import AppFooter from "./Footer.vue";

export default {
  name: "app-layout",
  components: { AppHeader, AppFooter },
  
};
</script>

<style></style>
