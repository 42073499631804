import datepicker from "./datepicker";
import multiselect from "./multiselect";

const ComponentPlugin = {
  install(Vue, options) {
    [datepicker, multiselect].forEach((component) => {
      Vue.component(component.name, component);
    });
  },
};

export default ComponentPlugin;
